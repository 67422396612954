import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../types/state/AppState";
import { isRequesting } from "../../../utils";
import { SORT, SORTING_TYPE, constants, issuanceMonitorWidget, roles } from "../../../constants";
import { Table } from "../../bidding/common/table";
import { topIssuersColumns } from "./columns/TopIssuersColumns";
import { Pagination } from "../tables/Pagination";
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from "../widget";
import { DashboardSkeleton } from "../DashboardSkeleton";
import {
    dashboardFetchTopIssuers,
    dashboardExportTopIssuers,
} from "../../../actions/dashboard.actions";
import { useDispatch } from "react-redux";
import { TopChartCompany } from "../../../types/dashboard/TopChartCompany";
import { PipelineWidgetColumn } from "../../../types/dashboard/PipelineWidgetColumn";
import { getAvailableYears, getSortingParamsForExport } from "./top-list.utils";
import { DropDownList, DropDownListItem } from "../../controls/DropDownList";
import { YearSelector } from "../../controls/YearSelector";
import { DashboardNoSelectedFilters } from "../DashboardNoSelectedFilters";
import { useSortedList } from "../../../effects";
import { ShowFor } from "../../access";
import ExportButton from "../../amrPipeline/common/ExportButton";
import { CollateralTypesList } from "../../../constants/dashboard/collateral-types";
import { amrChartUtils } from "../../../utils/amr-chart.utils";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { ActionBlocker } from "../../access/ActionBlocker";
import { TopIssuersCurrencyTypes, TopIssuersTransactionTypes } from './constants';

const availableYears: number[] = getAvailableYears();

const getFilterValue = (value: string) =>
    value === constants.allListItem ? undefined : value;

interface Props {
    onListItemClick?: () => void;
}

export function TopIssuersWidget({ onListItemClick }: Props) {
    const [type, setType] = useState(constants.allListItem);
    const [currency, setCurrency] = useState(constants.allListItem);
    const [year, setYear] = useState<number | undefined>(availableYears[0]);
    const [page, setPage] = useState(1);
    const [collateralType, setCollateralType] = useState(CollateralTypesList[0].value);

    const [sortBy, setSortBy] = useState("");
    const [sortDirection, setSortDirection] = useState(SORT.DESC);

    const topIssuers = useSelector((s: AppState) => s.dashboard.topIssuers);
    const requestStateFetchTopIssuers = useSelector(
        (s: AppState) => s.dashboard.requestStateFetchTopIssuers
    );
    const requestStateExportTopIssuers = useSelector(
        (s: AppState) => s.dashboard.requestStateExportTopIssuers
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            dashboardFetchTopIssuers(
                getFilterValue(type),
                getFilterValue(currency),
                year,
                getFilterValue(collateralType)
            )
        );
        setPage(1);
    }, [dispatch, type, currency, year, collateralType])

    const isSortByCompany = sortBy === PipelineWidgetColumn.company;
    const sortingType = isSortByCompany ? SORTING_TYPE.company : '';
    const [sortedDataItems] = useSortedList(topIssuers, sortBy, sortDirection, sortingType);

    const withSizeColumn = type !== constants.allListItem && currency !== constants.allListItem;

    const columnContext = () => ({
        onListItemClick,
        currency,
    });

    const handleTypeChange = ({ value }: DropDownListItem) => setType(value.toString());
    const handleCurrencyChange = ({ value }: DropDownListItem) => setCurrency(value.toString());
    const handleYearChange = (yearValue?: number) => setYear(yearValue);

    const handleSortChange = (field: string, direction: string) => {
        setSortBy(field);
        setSortDirection(direction);
    };

    const handleExportChart = () =>
        dispatch(
            dashboardExportTopIssuers(
                getFilterValue(type),
                getFilterValue(currency),
                year,
                getFilterValue(collateralType),
                getSortingParamsForExport(sortBy, sortDirection),
            )
        );
    const onPageChanged = (selectedPage: number) => setPage(selectedPage);

    const tableColumns = withSizeColumn
        ? topIssuersColumns.filter(c => c.column.columnKey !== PipelineWidgetColumn.emptySize)
        : topIssuersColumns.filter(c => c.column.columnKey !== PipelineWidgetColumn.dealBalanceTotal);

    const renderTable = () =>
        sortedDataItems.length > issuanceMonitorWidget.itemsOnPage ? (
            <Pagination
                list={sortedDataItems}
                itemsOnPage={issuanceMonitorWidget.itemsOnPage}
                currentPage={page}
                onPageChanged={onPageChanged}
            >
                {(items: TopChartCompany[]) => (
                    <Table
                        onSort={handleSortChange}
                        defaultSortBy={sortBy}
                        defaultSortByDirection={sortDirection}
                        dataItems={items}
                        columns={tableColumns}
                        createSecurityCustomArgs={columnContext}
                        className="data-list-striped"
                    />
                )}
            </Pagination>
        ) : (
            <Table
                onSort={handleSortChange}
                defaultSortBy={sortBy}
                defaultSortByDirection={sortDirection}
                dataItems={sortedDataItems}
                columns={tableColumns}
                createSecurityCustomArgs={columnContext}
                className="data-list-striped"
            />
        );

    const chartName = "Top-10 Issuers";

    return (
        <DashboardWidget className="top-issuers">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchTopIssuers)}>
                <DashboardWidgetHeader className="heading-wrap">
                    <h3>{chartName}</h3>
                    <ShowFor roles={roles.issuanceMonitorAccess()}>
                        <div className="flex-item-right">
                            <ExportButton
                                isExportLoad={isRequesting(requestStateExportTopIssuers)}
                                handleExport={handleExportChart}
                                disabled={!topIssuers.length}
                            />
                        </div>
                        <div className="control-box">
                            <div className="control-item">
                                <ActionBlocker feature={SubscriptionFeature.CanUseIssueTransactionChartFilter}>
                                    {blocked =>
                                        <DropDownList
                                            items={TopIssuersTransactionTypes.map(({ value, text }) => ({
                                                value,
                                                text,
                                                selected: value === type,
                                            }))}
                                            className="custom-drop-down-ghost"
                                            prefix="Type: "
                                            disabled={blocked}
                                            onChange={handleTypeChange}
                                        />}
                                </ActionBlocker>
                            </div>
                            <div className="control-item">
                                <ActionBlocker feature={SubscriptionFeature.CanUseIssueTransactionChartFilter}>
                                    {blocked =>
                                        <DropDownList
                                            items={TopIssuersCurrencyTypes.map(({ value, text }) => ({
                                                value,
                                                text,
                                                selected: value === currency,
                                            }))}
                                            disabled={blocked}
                                            className="custom-drop-down-ghost"
                                            defaultSortingEnabled={false}
                                            prefix="Ccy: "
                                            onChange={handleCurrencyChange}
                                        />}
                                </ActionBlocker>
                            </div>
                            <div className="control-item">
                                <ActionBlocker feature={SubscriptionFeature.CanUseIssueTransactionChartFilter}>
                                    {blocked =>
                                        <DropDownList
                                            items={CollateralTypesList.map(x => ({
                                                ...x,
                                                selected: collateralType === x.value,
                                            }))}
                                            disabled={blocked}
                                            defaultSortingEnabled={false}
                                            renderSelectedItemCallback={amrChartUtils.renderSelectedFilterOptionWithPrefix('Collat.')}
                                            className="custom-drop-down-ghost"
                                            onChange={({ value }) => setCollateralType(value as string)}
                                        />}
                                </ActionBlocker>
                            </div>
                            <div className="control-item">
                                <ActionBlocker feature={SubscriptionFeature.CanUseIssueTransactionChartFilter}>
                                    {blocked =>
                                        <YearSelector
                                            years={availableYears}
                                            selectedYear={year}
                                            currentYearPrefix="YTD"
                                            showAllOption
                                            prefix="Year: "
                                            disabled={blocked}
                                            onChange={handleYearChange}
                                        />}
                                </ActionBlocker>
                            </div>
                        </div>
                    </ShowFor>
                </DashboardWidgetHeader>
                <DashboardWidgetContent
                    requestState={requestStateFetchTopIssuers}
                    description={
                        !!sortedDataItems.length
                            ? 'All statistics are calculated based on the transaction pricing date.'
                            : ''
                    }
                >
                    {sortedDataItems.length ? renderTable() : <DashboardNoSelectedFilters />}
                </DashboardWidgetContent>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
