import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UsBslEsgNewIssueChart } from './UsBslEsgNewIssueChart';
import { UsBslEsgNewIssueFilter, UsBslEsgNewIssueChartFilter } from './UsBslEsgNewIssueFilter';
import { isRequesting } from '../../../utils';
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from '../widget';
import { AppState } from '../../../types/state/AppState';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { dashboardUsBslEsgNewIssueRequest } from '../../../actions';
import { ChartGrouping, ChartUnits } from '../../../types/dashboard/AmrChart';
import { DashboardNoSelectedFilters } from "../DashboardNoSelectedFilters";
import { ShowFor } from '../../access';
import { hasWidgetsAccess } from '../../../utils/amr-pipeline.utils';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

export function UsBslEsgNewIssueWidget() {
    const usBslEsgNewIssue = useSelector((state: AppState) => state.dashboard.usBslEsgNewIssue);
    const requestStateUsBslEsgNewIssue = useSelector((state: AppState) => state.dashboard.requestStateUsBslEsgNewIssue);

    const dispatch = useDispatch();
    const hasAccess = hasWidgetsAccess(SubscriptionFeature.EsgAsPercentOfBslNewIssueGraph);

    const [filterState, setFilterState] = useState<UsBslEsgNewIssueChartFilter>({
        grouping: ChartGrouping.M,
        units: ChartUnits.Volume,
    });

    const isDataLoaded = !!(usBslEsgNewIssue.esgLanguageDeals?.length || usBslEsgNewIssue.usBslNewIssue?.length);

    useEffect(() => {
        if (hasAccess) {
            dispatch(dashboardUsBslEsgNewIssueRequest(filterState.grouping));
        }
    }, [filterState, dispatch, hasAccess]);

    const chart = useMemo(() =>
        <UsBslEsgNewIssueChart
            filter={filterState}
            {...usBslEsgNewIssue}
        />,
        [usBslEsgNewIssue, filterState]);

    const chartName = "ESG as % of BSL New Issue";

    return (
        <DashboardWidget className="double-size im-chart">
            <DashboardSkeleton inProgress={isRequesting(requestStateUsBslEsgNewIssue)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.EsgAsPercentOfBslNewIssueGraph}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-esg-bsl-new-issue"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}</h3>
                        <ShowFor feature={SubscriptionFeature.EsgAsPercentOfBslNewIssueGraph}>
                            <UsBslEsgNewIssueFilter onChange={setFilterState} {...filterState} />
                        </ShowFor>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateUsBslEsgNewIssue}
                        description={isDataLoaded ? "All statistics are calculated based on the transaction pricing date." : ""}
                    >
                        {isDataLoaded ? chart : <DashboardNoSelectedFilters />}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
