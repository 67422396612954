import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UsBslEuCompliantDealsChart } from './UsBslEuCompliantDealsChart';
import { UsBslEuCompliantDealsFilter, UsBslEuCompliantDealsChartFilter } from './UsBslEuCompliantDealsFilter';
import { isRequesting } from '../../../utils';
import {
    DashboardWidget,
    DashboardWidgetContent,
    DashboardWidgetHeader,
} from '../widget';
import { AppState } from '../../../types/state/AppState';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { dashboardUsBslEuCompliantDealsRequest } from '../../../actions';
import { ChartGrouping, ChartUnits } from '../../../types/dashboard/AmrChart';
import { DashboardNoSelectedFilters } from "../DashboardNoSelectedFilters";
import { constants } from '../../../constants';
import { ShowFor } from '../../access';
import { hasWidgetsAccess } from '../../../utils/amr-pipeline.utils';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';

export function UsBslEuCompliantDealsWidget() {
    const usBslEuCompliantDealsState = useSelector((state: AppState) => state.dashboard.usBslEuCompliantDeals);
    const requestStateUsBslEuCompliantDeals = useSelector((state: AppState) => state.dashboard.requestStateUsBslEuCompliantDeals);
    const { usBslEuCompliantDeals, usBslDeals } = usBslEuCompliantDealsState;

    const dispatch = useDispatch();

    const hasAccess = hasWidgetsAccess(SubscriptionFeature.EuCompliantAsPercentOfBslGraph);

    const [filterState, setFilterState] = useState<UsBslEuCompliantDealsChartFilter>({
        grouping: ChartGrouping.M,
        units: ChartUnits.Volume,
        transactionType: constants.allListItem,
    });

    useEffect(() => {
        hasAccess && dispatch(dashboardUsBslEuCompliantDealsRequest(filterState));
    }, [hasAccess, dispatch, filterState]);

    const chart = useMemo(() =>
        <UsBslEuCompliantDealsChart
            filter={filterState}
            usBslEuCompliantDeals={usBslEuCompliantDeals}
            usBslDeals={usBslDeals}
        />,
        [
            usBslEuCompliantDeals,
            usBslDeals,
            filterState
        ]);

    const chartName = "EU Compliant as % of BSL";

    return (
        <DashboardWidget className="double-size im-chart">
            <DashboardSkeleton inProgress={isRequesting(requestStateUsBslEuCompliantDeals)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.EuCompliantAsPercentOfBslGraph}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-eu-compliant-bsl"
                >
                    <DashboardWidgetHeader>
                        <h3>{chartName}</h3>
                        <ShowFor feature={SubscriptionFeature.EuCompliantAsPercentOfBslGraph}>
                            <UsBslEuCompliantDealsFilter onChange={setFilterState} {...filterState} />
                        </ShowFor>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateUsBslEuCompliantDeals}
                        description={
                            <ShowFor feature={SubscriptionFeature.EuCompliantAsPercentOfBslGraph}>
                                All statistics are calculated based on the transaction pricing date.
                            </ShowFor>
                        }
                    >
                        {usBslEuCompliantDeals?.length || usBslDeals?.length ? chart : <DashboardNoSelectedFilters />}
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    );
}
