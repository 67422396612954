import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../types/state/AppState';
import { PriceLevelChart } from './PriceLevelChart';
import { isRequesting } from '../../../utils';
import { DashboardNoData } from "../DashboardNoData";
import { DashboardWidget, DashboardWidgetContent, DashboardWidgetHeader } from '../widget';
import { SubscriptionFeature } from '../../../types/billing/SubscriptionFeature';
import { DashboardSkeleton } from '../DashboardSkeleton';
import { dashboardInitPriceLevel } from '../../../actions';
import { ChartRequiredFeature } from '../../access/ChartRequiredFeature';
import { ShowFor } from '../../access';

const chartName = "Price Dispersion by Rating";

export function PriceLevelWidget() {
    const dispatch = useDispatch();
    const filter = useSelector((s: AppState) => s.dashboard.filter);
    const requestStateFetchPriceLevel = useSelector((s: AppState) => s.dashboard.requestStateFetchPriceLevel);
    const priceLevel = useSelector((s: AppState) => s.dashboard.priceLevel);
    const filterActive = useSelector((s: AppState) => s.dashboard.filterActive);
    const { selectedCurrencies, selectedRatings } = filter;
    const hasData = priceLevel?.isBusinessDay && !!priceLevel?.priceLevelData.length;

    useEffect(() => {
        dispatch(dashboardInitPriceLevel());
    }, [dispatch]);

    const filterChartData = () => {
        let data = [...priceLevel.priceLevelData];
        if (selectedCurrencies.length) {
            data = data.filter(d => selectedCurrencies.some(c => c === d.currency))
        }
        if (selectedRatings.length) {
            data = data.filter(d => selectedRatings.some(r => r === d.rating))
        }
        return data
    };

    const renderContent = () => {
        if (!hasData) {
            return (
                <DashboardNoData
                    imgName="no-data-on-security-found"
                    imgWidth={164}
                    imgHeight={128}
                    text={`No data available for the ${priceLevel.periodName}.`}
                />
            )
        }
        return <PriceLevelChart priceLevelData={filterChartData()} />
    };

    return (
        <DashboardWidget filterActive={filterActive} className="price-level">
            <DashboardSkeleton inProgress={isRequesting(requestStateFetchPriceLevel)}>
                <ChartRequiredFeature
                    feature={SubscriptionFeature.getPriceLevels}
                    chartName={chartName}
                    blockedClassName="restricted-placeholder-price-level"
                >
                    <DashboardWidgetHeader>
                        <h3>
                            {chartName}
                            {hasData && <span className="date">{priceLevel.periodName}</span>}
                        </h3>
                    </DashboardWidgetHeader>
                    <DashboardWidgetContent
                        requestState={requestStateFetchPriceLevel}
                        description={
                            <ShowFor feature={SubscriptionFeature.getPriceLevels}>
                                Updated daily at 5:00 PM EST.
                            </ShowFor>
                        }
                    >
                            {renderContent()}
                        <div className="description">

                        </div>
                    </DashboardWidgetContent>
                </ChartRequiredFeature>
            </DashboardSkeleton>
        </DashboardWidget>
    )
}
